import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

function createData(time, activity) {
  return { time, activity };
}

const rows = [
  createData("8:00 - 8:30", "Príchod detí"),
  createData("9:00 - 10:00", "Angličtina"),
  createData("10:00 - 10:30", "Desiata"),
  createData("10:30 - 11:30", "Angličtina"),
  createData("12:00 - 13:00", "Obed"),
  createData("13:00 - 16:00 ", "Táborové aktivity, olovrant"),
  createData("16:00 - 16:30", "Vyzdvihnutie detí"),
];

export default function BasicTable() {
  return (
    <TableContainer
      sx={{
        maxWidth: 850,
        borderTopRightRadius: "1rem",
        borderTopLeftRadius: "1rem",
      }}
      component={Paper}
    >
      <Table
        sx={{
          maxWidth: 850,
          th: { borderTopLeftRadius: "1rem", borderTopRightRadius: "1rem" },
        }}
        aria-label="aktivita table"
      >
        <TableHead>
          <TableRow sx={{ background: "#ff4820" }}>
            <TableCell
              sx={{ color: "white", fontSize: "1.5rem", fontFamily: "Poppins" }}
              width={150}
            >
              Čas
            </TableCell>
            <TableCell
              sx={{ color: "white", fontSize: "1.5rem", fontFamily: "Poppins" }}
            >
              Program
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                background: "#ff7b5d",
              }}
            >
              <TableCell sx={{ color: "white", fontSize: "1.2rem" }}>
                {row.time}
              </TableCell>
              <TableCell sx={{ color: "white", fontSize: "1.2rem" }}>
                {row.activity}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
