export const campImages = [
  {
    src: require("../../assets/camp/camp-2024-girls-boys.webp"),
    alt: "Camp 2024 girls and boys",
  },
  {
    src: require("../../assets/camp/camp-2024-girls-boys-drawing.webp"),
    alt: "Camp 2024 girls and boys drawing",
  },
  {
    src: require("../../assets/camp/camp-2024-girls-painting.webp"),
    alt: "Camp 2024 girls painting",
  },
  {
    src: require("../../assets/camp/camp-2024-girls-playground.webp"),
    alt: "Camp 2024 girls on the playground",
  },
  {
    src: require("../../assets/camp/camp-2024-girls-swimming.webp"),
    alt: "Camp 2024 girls swimming",
  },
];
