import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { Controller } from "react-hook-form";
import MenuItem from "@mui/material/MenuItem";

const ReactHookFormSelect = ({
  name,
  label,
  register,
  defaultValue,
  children,
  rules,
  options,
  control,
  ...props
}) => {
  const labelid = `${name}-label`;
  return (
    <FormControl {...props}>
      <InputLabel
        sx={{
          "&.MuiInputLabel-root": {
            "-webkit-transform": "translate(14px, 52px) scale(1)",
            "-moz-transform": "translate(14px, 52px) scale(1)",
            "-ms-transform": "translate(14px, 52px) scale(1)",
            transform: "translate(14px, 52px) scale(1)",
            color: "#040c18",
          },
          "&.Mui-focused": {
            "-webkit-transform": "translate(14px, 32px) scale(0.75)",
            "-moz-transform": "translate(14px, 32px) scale(0.75)",
            "-ms-transform": "translate(14px, 32px) scale(0.75)",
            transform: "translate(14px, 32px) scale(0.75)",
            color: "#040c18",
          },
          "&.MuiInputLabel-shrink": {
            "-webkit-transform": "translate(14px, 32px) scale(0.75)",
            "-moz-transform": "translate(14px, 32px) scale(0.75)",
            "-ms-transform": "translate(14x, 32px) scale(0.75)",
            transform: "translate(14px, 32px) scale(0.75)",
            color: "#040c18",
          },
        }}
        id={labelid}
      >
        {label}
      </InputLabel>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Select
            {...register(name, { ...rules })}
            sx={{
              height: "46px",
              marginTop: "40px",
              ".MuiOutlinedInput-notchedOutline": {
                borderWidth: "2px",
                borderStyle: "inset",
                borderColor: "rgb(133, 133, 133)",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#ff7b5d",
              },
            }}
            labelid={labelid}
            label={label}
          >
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </FormControl>
  );
};

export default ReactHookFormSelect;
