import React, { useRef, useState, useEffect } from "react";
import emailjs from "@emailjs/browser";
import {
  ErrorText,
  ReactHookFormInput,
  ReactHookFormSelect,
} from "../../../components";
import { ToastContainer, toast } from "react-toastify";
import "./campForm.css";
import { Checkbox } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

function CampForm({
  register,
  setValue,
  reset,
  errors,
  handleSubmit,
  watch,
  control,
}) {
  const [btnMsg, setBtnMsg] = useState("Objednať");
  const form = useRef();
  const [border, setBorder] = useState("1px dashed black");
  const specialRules = {
    phonenumber: {
      required: "Telefónne číslo je povinné!",
      pattern: {
        value: /^\+(?:[0-9]●?){6,14}[0-9]$/,
        message: "Uveďte svoje telefónne číslo s medzinárodnou predvoľbou.",
      },
    },
    email: {
      required: "E-mail je povinný!",
      pattern: {
        value:
          /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
        message: "Uistite sa, že ste zadali platnú e-mailovú adresu.",
      },
    },
    name: { required: "Meno je povinné!" },
    lastname: { required: "Priezvisko je povinné!" },
    date: { required: "Turnus je povinný!" },
  };

  const showToastMessage = (style) => {
    if (style === "succes")
      toast.success("Vaša registrácia prebehla úspešne!", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    if (style === "error")
      toast.error(
        "Niečo sa pokazilo! Skúste to znova alebo nám pošlite email na info@bratilingua.sk.",
        { position: toast.POSITION.BOTTOM_CENTER }
      );
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();

    await emailjs
      .sendForm(
        "service_u9uy4lg",
        "template_yszl4hm",
        form.current,
        "xuXCBlkR2IoX7u5LA"
      )
      .then(
        (result) => {
          showToastMessage("succes");
          setBtnMsg("Odoslané");
          reset();
        },
        (error) => {
          showToastMessage("error");
          setBtnMsg("Skúsiť znova");
        }
      );
  };

  useEffect(() => {
    if (errors.terms) setBorder("2px dashed red");
    else setBorder("2px dashed black");
  }, [errors]);

  return (
    <form ref={form} onSubmit={handleSubmit(onSubmit)} className="bl_form">
      <div className="camp_form_section">
        <div className="bl_camp_form-fieldContainer">
          {errors?.firstname && (
            <ErrorText message={errors.firstname.message} />
          )}
          <ReactHookFormInput
            id="firstname"
            name="firstname"
            label="Meno"
            register={register}
            defaultValue=""
            variant="outlined"
            margin="none"
            multiline={false}
            type="text"
            rules={specialRules.name}
          />
        </div>
        <div className="bl_camp_form-fieldContainer">
          {errors?.lastname && <ErrorText message={errors.lastname.message} />}
          <ReactHookFormInput
            id="lastname"
            name="lastname"
            label="Priezvisko"
            register={register}
            defaultValue=""
            variant="outlined"
            margin="none"
            multiline={false}
            type="text"
            rules={specialRules.lastname}
          />
        </div>
      </div>
      <div className="form_section">
        <div className="bl_camp_form-fieldContainer">
          {errors?.phonenumber && (
            <ErrorText message={errors.phonenumber.message} />
          )}
          <ReactHookFormInput
            id="phonenumber"
            name="phonenumber"
            label="Číslo: +421 ..."
            register={register}
            defaultValue=""
            variant="outlined"
            margin="none"
            multiline={false}
            type="text"
            rules={specialRules.phonenumber}
          />
        </div>
        <div className="bl_camp_form-fieldContainer">
          {errors?.email && <ErrorText message={errors.email.message} />}
          <ReactHookFormInput
            id="email"
            name="email"
            label="E-mail"
            register={register}
            defaultValue=""
            variant="outlined"
            margin="none"
            multiline={false}
            type="email"
            rules={specialRules.email}
          />
        </div>
      </div>
      <div className="form_section">
        <div className="bl_form-fieldContainer">
          {errors?.date && <ErrorText message={errors.date.message} />}
          <ReactHookFormSelect
            id="date"
            name="date"
            label="Turnus"
            register={register}
            defaultValue=""
            variant="outlined"
            margin="none"
            rules={specialRules.date}
            control={control}
            options={[
              { value: "", label: "Vybrať" },
              {
                value: "14.07.2025 - 18.07.2025",
                label: "14.07.2025 - 18.07.2025",
              },
              {
                value: "21.07.2025 - 25.07.2025",
                label: "21.07.2025 - 25.07.2025",
              },
              {
                value: "11.08.2025 - 15.08.2025",
                label: "11.08.2025 - 15.08.2025",
              },
            ]}
          />
        </div>
      </div>
      <div className="bl_form-fieldContainer messageField">
        <ReactHookFormInput
          id="message"
          name="message"
          label="Správa"
          register={register}
          defaultValue=""
          variant="outlined"
          margin="none"
          multiline={true}
          type="text"
          placeholder="Uveďte ďalšie informácie, napr. alergie"
        />
      </div>
      <div className="bl_camp_form-fieldContainer">
        <FormGroup
          style={{
            alignItems: "center",
            justifyItems: "center",
            marginTop: "2rem",
            border: border,
          }}
        >
          {errors?.terms && (
            <ErrorText
              className="bl_errorText_checkbox"
              message={errors.terms.message}
            />
          )}
          <FormControlLabel
            control={
              <Checkbox
                {...register("terms", { required: "Zaškrtnite prosím" })}
              />
            }
            label="vyplnením a odoslaním formuláru súhlasíte s našimi obchodnými podmienkami a GDPR"
          />
        </FormGroup>
      </div>
      <div className="buttonWrapper">
        <button type="submit" className="button-submit">
          {btnMsg}
        </button>
      </div>
      <ToastContainer />
    </form>
  );
}

export default CampForm;
