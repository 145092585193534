import React from "react";
import { useForm } from "react-hook-form";
import { Form } from "../../components";
import "react-toastify/dist/ReactToastify.css";
import "./signUp.css";

const SignUp = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    watch,
    control,
  } = useForm({
    defaultValues: {
      courseType: "",
      course: "",
      proficiency: "",
      location: "",
      quantity: "",
      isShowCase: false,
    },
  });

  return (
    <div className="bl_signup section__padding section_shadow">
      <div className="bl_signup-container">
        <h2>Chcete sa prihlásiť?</h2>
        <div className="flex-row">
          <p>
            Máte záujem učiť sa s nami angličtinu alebo španielčinu? Neváhajte a
            vyplňte formulár!
          </p>
        </div>
        <Form
          register={register}
          handleSubmit={handleSubmit}
          errors={errors}
          setValue={setValue}
          reset={reset}
          watch={watch}
          control={control}
        />
      </div>
    </div>
  );
};

export default SignUp;
